
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { GetStaticPaths, GetStaticProps, GetStaticPropsContext, InferGetStaticPropsType } from "next"
import useTranslation from "next-translate/useTranslation"
import React from "react"
import { fetchPages } from "react-bricks/frontend"

import { BlogCategoryContent } from "@app/components/blog-category"
import { Seo } from "@app/components/seo"
import { Layout } from "@app/shared/components/layout"
import { PageKind } from "@app/shared/types/pages"
import { getBlogCategoriesFromLocale } from "@app/shared/utils/pages/getBlogCategoriesFromLocale"
import { BlogCategoryType } from "@shared/types/website-pages"
import * as casavoPages from "@shared/utils/pages"

const PAGE_SIZE = 9

const CategoryPage: React.FC<InferGetStaticPropsType<typeof wrappedGetStaticProps>> = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <Seo
        alternates={props.seo.alternates}
        canonical={props.seo.canonical}
        description={t(`blog:${props.tag}.meta.description`)}
        og={{
          images: [
            { url: (process.env.NEXT_PUBLIC_IMAGE_PATH || "").concat("/images/seo/casavo-og-image.png") },
          ],
          type: "website",
          url: props.seo.canonical,
        }}
        title={t(`blog:${props.tag}.meta.title`)}
      />
      <Layout>
        <BlogCategoryContent {...props} totalPages={props.meta.totalPages} />
      </Layout>
    </>
  )
}

// Data fetching
const wrappedGetStaticProps = async (ctx: GetStaticPropsContext) => {
  if (!process.env.REACT_BRICKS_API_KEY) {
    throw Error("No api key found")
  }

  const tag = (
    Array.isArray(ctx.params?.tag) ? ctx.params?.tag[0] : ctx.params?.tag
  ) as keyof BlogCategoryType
  if (tag) {
    const locales = casavoPages.countryLocales(ctx.locales as Locale[])
    const seoBuilder = casavoPages.seo(process.env.NEXT_PUBLIC_BASE_PATH as string)(PageKind.blog)

    const seo = {
      alternates: seoBuilder
        .alternates(locales)
        .map((url) => ({ ...url, href: url.href.concat(`category/${tag}/`) })),
      canonical: seoBuilder.canonical(ctx.locale as Locale).concat(`category/${tag}/`),
    }

    const response = await fetchPages(process.env.REACT_BRICKS_API_KEY, {
      page: 1,
      pageSize: PAGE_SIZE,
      sort: "-publishedAt",
      tag,
      type: ctx.locale,
      usePagination: true,
    })

    const mostReadPosts = await fetchPages(process.env.REACT_BRICKS_API_KEY, {
      sort: "-publishedAt",
      type: `most-read-${ctx.locale}`,
    })

    return {
      props: {
        meta: response.pagination,
        mostReadPosts,
        posts: response.items,
        seo,
        tag,
      },
      revalidate: 3600,
    }
  } else {
    return {
      notFound: true,
      props: {} as never,
    }
  }
}

 const _getStaticProps: GetStaticProps = wrappedGetStaticProps

// Build routes
export const getStaticPaths: GetStaticPaths = async (ctx) => {
  const locales = ctx.locales || []

  const tempPaths = await Promise.all(
    locales
      .filter((locale) => locale !== casavoPages.DEFAULT_LOCALE)
      .map(async (locale) => {
        const categories: BlogCategoryType = await getBlogCategoriesFromLocale(locale as Locale)
        return Object.keys(categories)
          ?.filter((category) => category !== "customer-stories")
          .map((tag) => ({
            locale,
            params: {
              tag,
            },
          }))
      })
  )

  const paths = tempPaths.reduce((acc, localePaths) => [...acc, ...localePaths], [])
  return { fallback: false, paths }
}

export default CategoryPage


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/blog/category/[tag]/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  