import useTranslation from "next-translate/useTranslation"
import { Link, types } from "react-bricks/frontend"

import { CategoryMenu } from "@app/components/blog/CategoryMenu"
import { PageSection } from "@app/shared/components/layout/PageSection"
import { MyImage } from "@app/shared/components/MyImage"
import { TypographyOld } from "@app/shared/components/TypographyOld"
import theme from "@app/shared/design-lib/style-theme/theme.css"
import { imageFitContain } from "@app/shared/design-lib/styles.css"
import { PageKind } from "@app/shared/types/pages"
import { normalizeReactBricksPagesList } from "@app/shared/utils/functions/normalizeReactBricksPagesList"
import { BlogCategoryType } from "@shared/types/website-pages"
import * as casavoPages from "@shared/utils/pages"

import { ArticleCard, ArticleDimension } from "../../shared/components/ArticleCard"
import { BlogPageNavigation } from "../blog/BlogPageNavigation"
import { RecommendedArticles } from "../blog-post/RecommendedArticles"

import {
  articlesWrapper,
  flex,
  imageWrapper,
  link,
  marginLeft,
  noResults,
  paddingRight,
  space,
  typography,
} from "./style.css"

interface Props {
  mostReadPosts?: Array<types.PageFromList>
  posts: Array<types.PageFromList>
  tag: keyof BlogCategoryType | string
  totalPages?: number
}

export const BlogCategoryContent = ({ mostReadPosts = [], posts, tag, totalPages = 1 }: Props) => {
  const { lang, t } = useTranslation()
  const articles = normalizeReactBricksPagesList(lang as Locale, posts)
  const categoryTitle: string = t(`blog:categories.${tag}`)
  const blogUrl = `/${lang}/${casavoPages.create(lang as Locale)(PageKind.blog)}`

  return (
    <>
      {tag === "customer-stories" ? null : <CategoryMenu selected={tag} showAllArticles={true} />}
      <PageSection hasPadding={true} marginTop="xl" size="large">
        <div className={flex({ hasMargin: tag === "customer-stories" })}>
          <div className={imageWrapper}>
            <MyImage
              alt={tag}
              className={imageFitContain}
              height={200}
              src={`/images/blog/illustrations/categories/${tag}.svg`}
              width={200}
            />
          </div>
          <div className={marginLeft}>
            <TypographyOld
              className={typography({ margin: "bottom" })}
              color={theme.colors.greyscale400}
              component="h4"
              variant="bodyL_strong"
            >
              {tag === "all-articles" ? t(`blog:headline.all-articles`) : t(`blog:headline.category`)}
            </TypographyOld>
            <TypographyOld component="h1" variant="heroTitle">
              {tag === "all-articles" ? t("blog:categoryMenu.allArticles") : categoryTitle}
            </TypographyOld>
          </div>
        </div>
        <TypographyOld className={typography({ margin: "top" })} variant="bodyL">
          {t(`blog:description.${tag}`)}
        </TypographyOld>
      </PageSection>

      <BlogPageNavigation tag={tag} totalPages={totalPages} />

      {articles.length < 1 ? (
        <div>
          <TypographyOld alignment="center" className={typography({ margin: "top" })} variant="h1">
            {t(`blog:no-results`)}
          </TypographyOld>
          <div className={noResults}>
            <Link aria-label={t(`blog:no-results`)} href={blogUrl}>
              <a aria-label={t(`blog:no-results`)} className={link}>
                <span className={paddingRight}>
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 10 16"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M8.58579 0.292847L0.878679 7.99995L8.58579 15.7071L10 14.2928L3.70789 7.99995L10 1.70706L8.58579 0.292847Z"
                      fill={theme.colors.greyscale600}
                      fillRule="evenodd"
                    />
                  </svg>
                </span>
                <TypographyOld color="inherit" component="h5" variant="bodyL_strong">
                  {t("blog:categoryMenu.linkHome")}
                </TypographyOld>
              </a>
            </Link>
          </div>
        </div>
      ) : (
        <PageSection hasPadding={true} size="large">
          <div className={articlesWrapper}>
            {articles.map((article, index) => (
              <ArticleCard
                key={`article-${index}`}
                dimension={ArticleDimension.Small}
                props={article}
                {...article}
              />
            ))}
          </div>
        </PageSection>
      )}

      <BlogPageNavigation tag={tag} totalPages={totalPages} />

      {lang === "it" || lang === "en" ? (
        <RecommendedArticles greyCard={true} lightBlue={true} recentArticles={mostReadPosts.slice(0, 3)} />
      ) : (
        <div className={space} />
      )}
    </>
  )
}
